import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BioLink from "../components/BioLink"

import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? children : wrapper(children)

const BioLinkPage = ({ data, ...props }) => {
  const isMobile = useMediaQuery("(max-width:768px)")

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children) => <Layout maxWidth={"md"}>{children}</Layout>}
    >
      <SEO
        title="CCO Biolink"
        keywords={[`Center Consoles Only`, `CCO`, `Boats`]}
      />
      <BioLink data={data} {...props} isMobile={isMobile} />
    </ConditionalWrapper>
  )
}
export default BioLinkPage

export const query = graphql`
  query {
    allNodeIgPost(
      limit: 100
      sort: { fields: created, order: DESC }
      filter: {
        relationships: {
          field_tags: { elemMatch: { name: { in: "Listing" } } }
        }
      }
    ) {
      edges {
        node {
          title
          field_cloudinary
          created
          relationships {
            field_tags {
              name
            }
          }
        }
      }
    }
    allNodeListing {
      edges {
        node {
          title
          drupal_internal__nid
          path {
            alias
          }
        }
      }
    }
  }
`
