import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "gatsby"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
import withStyles from "@material-ui/core/styles/withStyles"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Header from "../Header/Header"
// Logos
import desktopLogo from "../../assets/img/cco_ig_logo.jpg"
import logo from "../../images/logo.svg"

const baseUrl = `${process.env.GATSBY_API_URL}`

const style = {
  root: {
    height: "auto",
    overflow: "hidden",
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
  },
  rootDesktop: {
    height: "auto",
    overflow: "hidden",
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
    marginTop: "86px",
    marginBottom: "20px",
  },
  container: {
    marginTop: "82px",
    marginBottom: "10px",
  },
  desktopContainer: {
    marginTop: "35px",
    marginBottom: "10px",
    width: "100%",
    paddingLeft: "15px",
  },
  itemsDesktop: {
    paddingLeft: "0",
    paddingRight: "28px",
    marginBottom: "28px",
  },
  itemsMobile: {
    paddingLeft: "2px",
    paddingRight: "2px",
    marginBottom: "2px",
  },
  noPaddingRight: {
    paddingRight: "0 !important",
  },
  header: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  logo: {
    verticalAlign: "middle",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    boxShadow: "0px 0px 0px 5px #eee, 0px 0px 0px 9px #ff4514",
  },
  logoMobile: {
    verticalAlign: "middle",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    margin: "20px",
    // boxShadow: "0px 0px 0px 2px #eee, 0px 0px 0px 5px #ff4514",
  },
  list: {
    padding: "0",
    marginLeft: "1em",
    listStyleType: "none",
    fontWeight: "400",
  },
  headerMobile: {
    position: "fixed",
    padding: "0.625rem 0",
    minHeight: "50px",
    backgroundColor: "white",
    zIndex: 1100,
    color: "black",
    marginBottom: "20px",
    flexShrink: 0,
  },
  headerMobileContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
}

const BioLink = (props) => {
  const [posts, setPosts] = useState(props.data.allNodeIgPost.edges)
  const { isMobile } = props
  const { classes } = props
  const lastPostTime =
    props.data.allNodeIgPost.edges.length > 0
      ? props.data.allNodeIgPost.edges[0].node.created
      : new Date()

  // console.log(posts)

  useEffect(() => {
    const source = axios.CancelToken.source()
    const endpoint = "/jsonapi/node/ig_post"
    const filters =
      "?filter[taxonomy_term--tags][condition][path]=field_tags.name" +
      "&filter[taxonomy_term--tags][condition][operator]=IN" +
      "&filter[taxonomy_term--tags][condition][value][]=Listing" +
      "&filter[date-filter][condition][path]=created" +
      `&filter[date-filter][condition][operator]=%3E` +
      `&filter[date-filter][condition][value]=${
        new Date(lastPostTime).getTime() / 1000
      }&include=field_tags`
    const fetchData = async () => {
      try {
        await axios
          .get(baseUrl + endpoint + filters, {
            cancelToken: source.token,
          })
          .then(function (response) {
            // const sig = response.data.signature
            // console.log(response.data.included)
            const { data } = response.data
            const count = typeof data !== "undefined" ? data.length : 0
            if (count > 0) {
              const newestPost = data[count - 1]
              let dateA = new Date(lastPostTime),
                dateB = new Date(newestPost.attributes.created)
              if (dateB > dateA) {
                let newPosts = []
                for (let i = 0; i < data.length; i++) {
                  const { created, title, field_cloudinary } = data[
                    i
                  ].attributes
                  newPosts[i] = {
                    node: {
                      title: title,
                      created: created,
                      field_cloudinary: field_cloudinary,
                      relationships: {
                        field_tags: getTagNameById(
                          data[i].relationships.field_tags.data,
                          response.data.included
                        ),
                      },
                    },
                  }
                }
                setPosts([...newPosts.reverse(), ...posts])
              }
            }
          })
      } catch (error) {
        if (axios.isCancel(error)) {
          //cancelled
        } else {
          throw error
        }
      }
    }
    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  if (props.data.allNodeIgPost.edges.length > 0) {
    // const posts = props.data.allNodeIgPost.edges

    const getListingById = (id) => {
      let listing = null
      const listings = props.data.allNodeListing.edges
      listings.map((l) => {
        if (l.node.drupal_internal__nid === id) {
          listing = { alias: l.node.path.alias, title: l.node.title }
        }
      })
      return listing
    }
    const features = [
      "- Sea Trials",
      "- Boat Reviews",
      "- Factory Tours",
      "- Digital Creation",
      "- Product Reviews",
    ]
    return (
      <div className={isMobile ? classes.root : classes.rootDesktop}>
        <Header
          transparent
          brand={logo}
          fixed
          color={"white"}
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          storeContext={null}
          bioLink
        />

        <GridContainer
          className={isMobile ? classes.container : classes.desktopContainer}
          spacing={0}
        >
          {!isMobile ? (
            <GridItem className={classes.header} xs={12}>
              <GridContainer spacing={0}>
                <GridItem xs={1} />
                <GridItem xs={3}>
                  <img
                    className={classes.logo}
                    alt={"Center Consoles Only"}
                    src={desktopLogo}
                  />
                </GridItem>
                <GridItem style={{ marginTop: "-30px" }} xs={6}>
                  <h2 style={{ fontWeight: "500" }}>centerconsolesonly</h2>
                  <GridContainer
                    spacing={0}
                    style={{ fontWeight: "500", marginBottom: "10px" }}
                  >
                    <GridItem xs={4}>6,100+ posts</GridItem>
                    <GridItem xs={4}>168k+ followers</GridItem>
                    <GridItem xs={4}>1,630 following</GridItem>
                  </GridContainer>
                  <span style={{ display: "block", fontWeight: "600" }}>
                    Center Consoles Only
                  </span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    #1 Center Console Boat Resource Worldwide
                  </span>
                  <ul className={classes.list}>
                    {features.map((feature, idx) => (
                      <li key={idx}>{feature}</li>
                    ))}
                  </ul>
                </GridItem>
                <GridItem xs={2} />
              </GridContainer>
            </GridItem>
          ) : (
            <GridItem xs={12}>
              <GridContainer style={{ textAlign: "center" }} spacing={5}>
                <GridItem xs={12}>
                  <span style={{ verticalAlign: "middle" }}>
                    <p>Tap on any image to learn more.</p>
                  </span>
                </GridItem>
              </GridContainer>
            </GridItem>
          )}
          {posts.map((item, idx) => {
            const {
              field_cloudinary,
              relationships: { field_tags },
            } = item.node
            const id = getIdByTag(field_tags)

            if (id) {
              const listing = getListingById(id)
              if (listing) {
                const { alias, title } = listing
                const firstImage = JSON.parse(field_cloudinary)[0]
                return (
                  <GridItem
                    className={
                      isMobile
                        ? classes.itemsMobile
                        : classes.itemsDesktop +
                          " " +
                          (isMobile ? idx % 3 : (idx + 1) % 3)
                        ? classes.noPaddingRight
                        : ""
                    }
                    key={idx}
                    xs={isMobile && idx === 0 ? 12 : 4}
                    style={{ marginBottom: idx === 0 ? "8px" : "inherit" }}
                  >
                    <Link to={alias}>
                      <CloudinaryMediaSingle
                        image={firstImage}
                        transformations={"q_auto,w_400,h_400,c_fill"}
                        alt={title}
                      />
                    </Link>
                    {isMobile && idx === 0 && (
                      <h5
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          fontWeight: "500",
                        }}
                      >
                        FEATURED LISTING: <br /> {title.toUpperCase()}
                      </h5>
                    )}
                  </GridItem>
                )
              }
            }
          })}
        </GridContainer>
      </div>
    )
  }

  return null
}

const getIdByTag = (tags) => {
  let id = null
  tags.map((tag) => {
    if (tag.name.search("ID:") > -1) {
      id = parseInt(tag.name.replace("ID:", ""))
    }
  })
  return id
}

const getTagNameById = (tags, inc) => {
  let names = []
  tags.map((t, index) => {
    inc.map((i) => {
      if (i.id === t.id) {
        names[index] = { name: i.attributes.name }
      }
    })
  })
  return names
}

export default withStyles(style)(BioLink)
